<template>
  <div
    class="formdata"
    style="padding: 20px;"
  >
    <!-- 处方订单 -->
    <el-form
      ref="form"
      :rules="rules"
      label-width="120px"
    >
      <h4 class="title">
        处方信息
      </h4>
      <el-row>
        <el-col :span="10">
          <el-form-item label="医生姓名 :">
            <span>{{ form.doctorName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="开方时间 :">
            <span>{{ form.createTime }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="处方号 :">
            <span>{{ form.id }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="审方时间  :">
            <span>{{ form.auditTime }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="就诊科室 :">
            <span>{{ form.deptName }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <h4 class="title">
        患者基本信息
      </h4>
      <el-row>
        <el-col :span="10">
          <el-form-item label="患者名称 :">
            <span>{{ form.patientName }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="年龄 :">
            <span>{{ form.patientAge }}岁</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="性别 :">
            <span>{{ sexDic[form.patientSex] }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="主诉 :">
            <span>{{ form.chiefComplaint }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <h4 class="title">
        诊断基本信息
      </h4>
      <el-form-item>
        <span>{{ form.diagnosisName }}</span>
      </el-form-item>
      <h4 class="title">
        处方药品信息
      </h4>
      <!-- <div class="prescription">
        <div v-for="item in form.medicals" :key="item.prescriptionId">
          <div class="drugSty">
            <div class="medical">
              {{ item.medicalName }}
            </div>
            <div class="medical">
              {{ item.specs }}
            </div>
            <div class="medical">
              {{ item.specsPacking }}
            </div>
            <div class="medical">
              {{ item.quantity }}
            </div>
          </div>
          <el-form-item label="用法用量:">
            <span class="medical">{{ item.medicalUsage }}</span>
            <span class="medical">{{ item.frequency }}</span>
            <span class="medical"
              >每次{{ item.consumption }}{{ item.minUseUnit }}</span
            >
            <span class="medical">连用{{ item.medicationDays }}天</span>
            <span class="medical">{{ item.entrust }}</span>
          </el-form-item>
        </div>
      </div> -->

      <div class="drugList">
        <div
          v-for="item in form.medicals"
          :key="item.prescriptionId"
        >
          <div style="margin-left: 50px; margin-bottom: 10px;display: flex;">
            <div class="medical">
              {{ item.medicalName }}
            </div>
            <div class="medical">
              {{ item.specs }}
            </div>
            <div class="medical">
              {{ item.specsPacking }}
            </div>
            <span>{{ item.quantity }}</span>
          </div>
          <el-form-item
            label="用法用量:"
            style="margin-left: 35px;"
          >
            <span class="medical">{{ item.medicalUsage }}</span>
            <span class="medical">{{ item.frequency }}</span>
            <span
              class="medical"
            >每次{{ item.consumption }}{{ item.minUseUnit }}</span>
            <span class="medical">连用{{ item.medicationDays }}天</span>
            <span class="medical">{{ item.entrust }}</span>
          </el-form-item>
        </div>
      </div>

      <el-form-item>
        <el-button @click="$router.go(-1)">
          返回
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { sexDic } from '@/utils/component/publicList';

  export default {
    name: 'GraphicDetails',
    // components: {
    //   HistoryDialog
    // },
    data() {
      return {
        sexDic: sexDic, // 性别字典
        form: {},
        rules: {}
      };
    },
    computed: {},
    watch: {},
    mounted() {
      this.getOrderDetail();
    },
    methods: {
      // 查看处方详情
      getOrderDetail() {
        this.form = JSON.parse(this.$route.query.prescriptionDetailData);
      }
    }
  };
</script>

<style lang="scss">
// 预约时间的可预约样式
</style>
<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle {
  margin-right: 10px;
}

.serviceDetail {
  font-size: 16px;
  color: red;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.orderstatus {
  padding: 10px 5px;
  margin-left: 40px;
  border: 1px solid black;
}

.title {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
}

.imgstyle {
  display: flex;
  //  justify-content:space-between;
}
// .prescription {
//   display: flex;
//   justify-content: space-between;
// }
// .drugSty {
//   margin-left: 50px;
//   // display: flex;
//   justify-content: space-around;
// }
.medical {
  margin-right: 20px;
}
</style>
